import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslate } from '@tolgee/react';
import classNames from 'classnames';
import fromUnixTime from 'date-fns/fromUnixTime';
import format from 'date-fns/format';
import { UnionFragment } from '@amf/shared/types/graphql';
import Button from '@amf/shared/components/button/Button';
import useOnClickOutside from '@amf/shared/hooks/useOnClickOutside';
import { getUnionContent } from '@amf/shared/data/Union';

interface UnionSliderItemProps {
  unions: UnionFragment[];
  union: UnionFragment;
  className?: string;
  setUnion: (id: string) => void;
}

export default function UnionSliderItem({
  unions,
  union,
  className,
  setUnion,
}: UnionSliderItemProps) {
  const { t } = useTranslate('malyfotbal');
  const [opened, setOpened] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setOpened(false));

  const openUnion = useCallback(
    (id: string) => {
      setOpened(false);
      setUnion(id);
    },
    [setUnion],
  );

  const filteredUnions = useMemo(() => unions.filter(u => u.id !== union.id), [union, unions]);
  const content = getUnionContent(union.id);

  const dateFounded = union.dateFounded ? fromUnixTime(union.dateFounded) : null;

  return (
    <div className={classNames('UnionSlider__item', className)}>
      <div className='UnionSlider__image'>
        <div className='UnionSlider__imageInner'>{content?.logo}</div>
      </div>
      <div className='UnionSlider__dropdown' ref={ref}>
        <button type='button' className='UnionSlider__title' onClick={() => setOpened(!opened)}>
          {union.name}{' '}
          {opened ? <i className='bi bi-chevron-up' /> : <i className='bi bi-chevron-down' />}
        </button>
        {opened && (
          <div className='UnionSlider__dropdownList'>
            {filteredUnions.map(({ id, name }) => (
              <a key={id} className='UnionSlider__dropdownItem' onClick={() => openUnion(id)}>
                {name}
              </a>
            ))}
          </div>
        )}
      </div>
      {dateFounded && (
        <div className='UnionSlider__numberList'>
          <div className='UnionSlider__numberItem'>
            <strong className='UnionSlider__numberValue'>{format(dateFounded, 'yyyy')}</strong>
            <div className='UnionSlider__numberLabel'>{t('home.union.founded')}</div>
          </div>
        </div>
      )}
      <div className='UnionSlider__numberList'>
        {union.countTeams !== null && (
          <div className='UnionSlider__numberItem'>
            <strong className='UnionSlider__numberValue'>{union.countTeams}</strong>
            <div className='UnionSlider__numberLabel'>{t('home.union.teams')}</div>
          </div>
        )}
        {union.countLeagues !== null && (
          <div className='UnionSlider__numberItem'>
            <strong className='UnionSlider__numberValue'>{union.countLeagues}</strong>
            <div className='UnionSlider__numberLabel'>{t('home.union.leagues')}</div>
          </div>
        )}
      </div>
      <a href={`http://${union.domain}`} target='_blank' rel='noreferrer'>
        <Button variant='blue'>{t('home.union.link')}</Button>
      </a>
    </div>
  );
}
