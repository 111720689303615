/* eslint-disable @next/next/no-img-element */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useKeenSlider } from 'keen-slider/react';
import { UnionFragment } from '@amf/shared/types/graphql';
import { getUnionContent } from '@amf/shared/data/Union';

import UnionSliderItem from 'components/union/UnionSliderItem';

const UnionSliderTransitionDelay = 10000;
const UnionSliderTransitionSpeed = 640;

interface UnionSliderProps {
  unions: UnionFragment[];
}

export default function UnionSlider({ unions }: UnionSliderProps) {
  const timer = useRef<number>();
  const [pause, setPause] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);

  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    duration: UnionSliderTransitionSpeed,
    loop: true,
    dragStart: () => setPause(true),
    dragEnd: () => setPause(false),
    slideChanged() {
      setSlideIndex(-1);
    },
    afterChange(s) {
      const index = s.details().relativeSlide;
      setSlideIndex(index);
    },
  });

  const filteredUnions = useMemo(
    () => unions.filter(union => !!getUnionContent(union.id)),
    [unions],
  );

  useEffect(() => {
    if (filteredUnions.length > 1) {
      window.clearInterval(timer.current);
      timer.current = window.setInterval(() => {
        if (!pause && slider) {
          slider.next();
        }
      }, UnionSliderTransitionDelay);
    }

    return () => {
      window.clearInterval(timer.current);
    };
  }, [slideIndex, pause, slider, filteredUnions]);

  const setUnion = useCallback(
    (id: string) => {
      const index = filteredUnions.findIndex(u => u.id === id);
      if (index < 0) {
        return;
      }
      slider.moveToSlide(index, 0);
    },
    [filteredUnions, slider],
  );

  return (
    <div className='UnionSlider'>
      <div ref={sliderRef} className='UnionSlider__container'>
        {filteredUnions.map(union => {
          const data = getUnionContent(union.id);
          if (!data) {
            return null;
          }

          return (
            <UnionSliderItem
              key={union.id}
              unions={filteredUnions}
              union={union}
              setUnion={setUnion}
              className='keen-slider__slide'
            />
          );
        })}
      </div>
      <button
        type='button'
        className='UnionSlider__button UnionSlider__button--left'
        onClick={() => slider.prev()}
      >
        <i className='bi bi-chevron-left' />
      </button>
      <button
        type='button'
        className='UnionSlider__button UnionSlider__button--right'
        onClick={() => slider.next()}
      >
        <i className='bi bi-chevron-right' />
      </button>
    </div>
  );
}
