import React from 'react';
import CountryFlag from 'react-country-flag';
import { useTranslate } from '@tolgee/react';
import classNames from 'classnames';
import { RankingFragment } from '@amf/shared/types/graphql';
import { getCountryISO2 } from '@amf/shared/utils/country';

interface RankingTableProps {
  rankings: RankingFragment[];
}

function RankingTable({ rankings }: RankingTableProps) {
  const { t } = useTranslate('malyfotbal');
  return (
    <div className='RankingTable'>
      {rankings.map(({ id, name, countryCode, points }, order) => (
        <div
          key={id}
          className={classNames('RankingTable__item', {
            'RankingTable__item--promoted': countryCode === 'CZE',
          })}
        >
          <div className='RankingTable__order'>#{order + 1}</div>
          <div className='RankingTable__name'>
            <CountryFlag
              svg
              className='RankingTable__flag'
              countryCode={getCountryISO2(countryCode)}
            />
            {name}
          </div>
          <div className='RankingTable__points'>
            <strong>{points.toFixed(2)}</strong>
            <span>{t('home.ranking.points')}</span>
          </div>
        </div>
      ))}
    </div>
  );
}

export default RankingTable;
