import Link from 'next/link';
import fromUnixTime from 'date-fns/fromUnixTime';
import format from 'date-fns/format';
import { TournamentListItemFragment } from '@amf/shared/types/graphql';

import { AppLinks } from 'utils/links';

interface TournamentListItemProps {
  tournament: TournamentListItemFragment;
}

export default function TournamentListItem({ tournament }: TournamentListItemProps) {
  const dateStart = fromUnixTime(tournament.dateStart);
  return (
    <Link href={`${AppLinks.tournamentList}/${tournament.id}`}>
      <a className='TournamentListItem'>
        <div className='TournamentListItem__icon' />
        <div className='TournamentListItem__content'>
          <h4 className='TournamentListItem__title'>{tournament.name}</h4>
          <p className='TournamentListItem__subtitle'>
            {tournament.year && <span>{tournament.year} | </span>}
            {tournament.category.name} | {format(dateStart, 'dd/MM/yyyy')} |{' '}
            {tournament.localityName}
          </p>
        </div>
      </a>
    </Link>
  );
}
