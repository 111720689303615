import Link from 'next/link';
import React from 'react';
import { useTranslate } from '@tolgee/react';
import classNames from 'classnames';
import { TournamentListItemFragment } from '@amf/shared/types/graphql';
import Button from '@amf/shared/components/button/Button';

import TournamentListItem from 'components/tournament/TournamentListItem';
import { AppLinks } from 'utils/links';

interface TournamentListProps {
  tournaments: TournamentListItemFragment[];
}

export default function TournamentList({ tournaments }: TournamentListProps) {
  const { t } = useTranslate('malyfotbal');
  return (
    <div
      className={classNames('TournamentList', {
        'TournamentList--withBackground': tournaments.length > 4,
      })}
    >
      {tournaments.length > 0 && (
        <div className='TournamentList__content'>
          {tournaments.map(tournament => (
            <TournamentListItem key={tournament.id} tournament={tournament} />
          ))}
        </div>
      )}
      {tournaments.length <= 4 && (
        <div className='TournamentList__create'>
          <h3 className='TournamentList__createTitle'>Vlastní turnaj</h3>
          <p className='TournamentList__createMessage'>
            Přidejte se k nám a založ svůj vlastní turnaj.
          </p>
          <Link href={AppLinks.tournamentNew}>
            <a>
              <Button variant='white-outline'>{t('home.tournaments.add')}</Button>
            </a>
          </Link>
        </div>
      )}
    </div>
  );
}
